<template>
  <div>
    <local-upload
      ref="upload"
      :pickfiles="'pickfiles-files-'+uploadUUID"
      :container="'container-files-'+uploadUUID"
      :documentDirId="-1"
      :autoStart="autoStart"
      :multiSelection="true"
      :filters="filters"
      :maxSize="maxSize"
      @onFilesAdded="onFilesAdded"
      @onOneUploadComplete="onOneUploadComplete"
      @onUploadComplete="onUploadComplete"
      @onBeforeUpload="onBeforeUpload"
      @onFileRemove="onFileRemove"
      @onError="onError">
      <div slot="uploader">
        <div :id="'container-files-'+uploadUUID">
          <div v-if="type==='edit'">
            <a-button type="dashed"
                      :loading="uploadStatus"
                      class="m-r-12 cursor-pointer"
                      icon="upload"
                      :id="'pickfiles-files-'+uploadUUID">上传文件
            </a-button>
            {{tips}}
          </div>
        </div>
      </div>
    </local-upload>
    <ul class="earl-upload-list w-450" :class="files.length === 0 && type === 'edit'?'m-t-12':''"
        v-if="currentDefaultValue.length > 0">
      <li class="earl-upload-list-file" :key="file.id" v-for="file in currentDefaultValue">
        <a-row>
          <a-col span="23">
            <a-icon
              :type="format(file.newName)"/>
            <a-tooltip placement="top">
              <template slot="title">
                <span>{{file.name}}</span>
              </template>
              <span>&nbsp;&nbsp;&nbsp;{{file.name | subString}}</span>
            </a-tooltip>
            <span
              style="float: right;font-size: small;padding-right: 10px;">{{humanReadableUnits(file.fileSize)}}</span>
          </a-col>
          <a-col span="1">
            <a-icon type="close" v-if="type==='edit'" class="earl-upload-list-remove"
                    @click.native="removeFile(file)"/>
            <a-icon type="download" v-if="type==='view'" class="earl-upload-list-download"
                    @click.native="downloadFile(file)"/>
          </a-col>
        </a-row>
      </li>
    </ul>
  </div>
</template>

<script>

  import uploadCommon from '../../components/LocalUpload/mixins/upload-common'
  import { fileIconFormat, humanReadableUnits } from '../../utils/utils'
  import LocalUpload from '../../components/LocalUpload'
  import dragAndDrop from '../../directives/drag-and-drop'
  import SERVICE_URLS from '../../api/service.url'
  import VueCookie from 'vue-cookie'
  import '../../plugins/fileDownload/download.min'
  import { DEFAULT_X_AUTH_TOKEN } from '../../store/mutation-types'

  export default {
    name: 'MultiUpload',
    props: {
      value: {
        type: Array
      },
      type: {
        type: String,
        default: 'edit'
      },
      // 默认已存在的文件数据
      defaultValue: {
        type: Array
      },
      maxSize: {
        type: String,
        default: '50mb'
      },
      autoStart: {
        type: Boolean,
        default: true
      },
      filters: {
        type: Object
      },
      tips: {
        type: String,
        default: '支持文件拓展名:.doc .docx .pdf'
      }
    },
    mixins: [uploadCommon],
    data () {
      return {
        format: fileIconFormat,
        humanReadableUnits: humanReadableUnits,
        currentValue: this.value || [],
        currentDefaultValue: this.defaultValue || [],
        files: [],
        // TODO uploadUUID逻辑
        // ↓这里的uploadUUID本身就是一个无效的值,加上这个变量可以不报错,但是需要完成他的逻辑
        uploadUUID: ''
      }
    },
    filters: {
      subString (value) {
        if (!value) return ''
        if (value.length > 20) {
          return value.substring(0, 20) + '...'
        }
        return value
      }
    },
    watch: {
      value (val) {
        this.currentValue = val
      },
      defaultValue (val) {
        this.currentDefaultValue = val
      }
    },
    methods: {
      // 上传增加
      onFilesAdded (files, allFiles) {
        this.files = allFiles
      },
      removeFile (file) {
        const temp = this.currentDefaultValue[this.currentDefaultValue.indexOf(file)]
        this.currentDefaultValue.splice(this.currentDefaultValue.indexOf(file), 1)
        this.currentValue.splice(this.currentValue.indexOf(temp.id), 1)
        this.change()
        this.$emit('removeFile', this.currentDefaultValue)
      },
      onOneUploadComplete (data) {
        this.uploadFileList.push(data)
        this.currentValue.push(data.id)
        this.change()
      },
      onFileRemove (file) {
        if (file['documentId']) { //已经保存成功
          const temp = this.uploadFileList.find(x => x.id === file.documentId)
          if (temp) {
            this.currentValue.splice(this.currentValue.indexOf(temp.id), 1)
            this.change()
            this.uploadFileList.splice(this.uploadFileList.indexOf(temp), 1)
            this.$emit('delFile', this.currentValue)
          }
        }
      },
      change () {
        this.$emit('change', this.currentValue)
        this.$emit('input', this.currentValue)
      },
      onUploadComplete () {
        this.uploadStatus = false
        // 所有文件上传完成 多文件上传需要监听
        this.$emit('onUploadComplete')
      },
      downloadFile (file) {
        let downUrl = SERVICE_URLS.document.download.path.replace('{id}', file.id) + '?attname=' + encodeURIComponent(file.name)
        const xAuthToken = VueCookie.get(DEFAULT_X_AUTH_TOKEN)
        if (!xAuthToken) {
          this.$Message.error({
            closable: true,
            content: '下载凭证丢失，请重新登录！',
            duration: 3
          })
          return
        }
        downUrl += '&' + DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken
        /*eslint-disable-next-line*/
        downloadFile(downUrl)
      },
    },
    components: {
      LocalUpload
    },
    directives: { dragAndDrop }
  }
</script>