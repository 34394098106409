<template>
  <div>
    <local-upload
      v-if="type==='edit'"
      ref="upload"
      :pickfiles="'pickfiles-files-'+uploadUUID"
      :container="'container-files-'+uploadUUID"
      :documentDirId="documentDirId"
      :autoStart="true"
      :instanceType="instanceType"
      :content="content"
      :multiSelection="false"
      :notShowDefaultList="true"
      @onFilesAdded="onFilesAdded"
      @onOneUploadComplete="onOneUploadComplete"
      @onUploadComplete="onUploadComplete"
      @onBeforeUpload="onBeforeUpload"
      @onFileRemove="onFileRemove"
      @onError="onError">
      <div slot="uploader">
        <a-tooltip v-model="currentVisible" placement="top">
          <template slot="title" v-if="currentDefaultValue">
            <span>已上传：{{currentDefaultValue.name}} {{humanReadableUnits(currentDefaultValue.fileSize)}}</span>
          </template>
          <div class="ant-upload ant-upload-select ant-upload-select-picture-card"
               @mouseleave="visible = false"
               @mouseenter="visible = true"
               :id="'container-files-'+uploadUUID">
            <span role="button" tabindex="0" class="ant-upload ant-upload-btn">
              <div :id="'pickfiles-files-'+uploadUUID">
                <a-icon v-if="!currentFile&&type==='edit'" type="plus" class="legend-color"
                        style="font-size: 36px"/>
                <a-progress v-if="currentFile&&type==='edit'"
                            :strokeWidth="4"
                            :width="40" type="circle"
                            :status="currentFile['error']?'exception':(currentFile.realSuccess?'success':'active')"
                            :percent="parseInt(currentFile.percent,10)"/>
                <a-tooltip placement="top">
                  <div class="ant-upload-text font-size-12"
                       style="display: block;height: 36px;">
                      <span>{{title | subString}}</span>
                  </div>
                  <!--<template slot="title">
                      <span>{{title}}</span>
                  </template>-->
                </a-tooltip>
              </div>
            </span>
          </div>
        </a-tooltip>
      </div>
    </local-upload>
    <div v-if="type==='view'">
      <a-tooltip v-model="currentVisible" placement="top">
        <template slot="title" v-if="currentDefaultValue">
          <span>已上传：{{currentDefaultValue.name}} {{humanReadableUnits(currentDefaultValue.fileSize)}}</span>
        </template>
        <div class="ant-upload ant-upload-select ant-upload-select-picture-card"
             @mouseleave="visible = false"
             @mouseenter="visible = true"
             :id="'container-files-'+uploadUUID">
            <span role="button" tabindex="0" class="ant-upload ant-upload-btn">
              <div :id="'pickfiles-files-'+uploadUUID">
                <a-icon v-if="!currentFile&&type==='view'" type="question-circle" class="legend-color"
                        @click.native="noFileInfo()"
                        style="font-size: 36px"/>
                <a-icon v-if="currentFile&&type==='view'" type="cloud-download"
                        @click.native="downloadFile()"
                        style="font-size: 36px;color:#49a764"/>
                <a-tooltip placement="top">
                  <div class="ant-upload-text font-size-12"
                       style="display: block;height: 36px;">
                      <span>{{title | subString}}</span>
                  </div>
                  <!--<template slot="title">
                      <span>{{title}}</span>
                  </template>-->
                </a-tooltip>
              </div>
            </span>
        </div>
      </a-tooltip>
    </div>
  </div>
</template>

<script>

  import uploadCommon from '../../components/LocalUpload/mixins/upload-common'
  import { fileIconFormat, humanReadableUnits } from '../../utils/utils'
  import LocalUpload from '../../components/LocalUpload'
  import SERVICE_URLS from '../../api/service.url'
  import VueCookie from 'vue-cookie'
  import '../../plugins/fileDownload/download.min'
  import { DEFAULT_X_AUTH_TOKEN } from '../../store/mutation-types'

  export default {
    name: 'SingleUpload',
    mixins: [uploadCommon],
    props: {
      value: {
        type: Number,
      },
      instanceType: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'edit'
      },
      documentDirId: {
        type: Number
      },
      title: {
        type: String,
        default: 'Upload'
      },
      content: {
        type: Object
      },
      // 一般用于编辑，传入服务端文件数据结构
      defaultValue: {
        type: Object
      }
    },
    filters: {
      subString (value) {
        if (!value) return ''
        if (value.length > 12) {
          return value.substring(0, 12) + '...'
        }
        return value
      }
    },
    data () {
      return {
        format: fileIconFormat,
        humanReadableUnits: humanReadableUnits,
        currentValue: this.value,
        currentDefaultValue: this.defaultValue,
        currentFile: this.defaultValue ? {
          percent: 100,
          error: false,
          realSuccess: true,
        } : null,
        visible: false
      }
    },
    /*created () {
      this.content.fileName = this.title
    },*/
    watch: {
      value (val) {
        this.currentValue = val
      },
      defaultValue (val) {
        this.currentDefaultValue = val
      }
    },
    computed: {
      currentVisible: {
        get () {
          return this.visible && !!this.currentDefaultValue
        },
        set (val) {
        }
      }
    },
    methods: {
      // 上传增加
      onFilesAdded (files, allFiles) {
        if (allFiles.length > 0) {
          this.currentFile = allFiles[0]
        } else {
          this.currentFile = null
        }
      },
      onBeforeUpload () {
        this.uploadFileList = []
        this.uploadStatus = true
      },
      onUploadComplete () {
        this.uploadStatus = false
        if (this.uploadFileList.length > 0) {
          this.currentValue = this.uploadFileList[0].id
          this.change()
          this.currentDefaultValue = this.uploadFileList[0]
          this.$emit('onUploadComplete', this.currentValue, this.instanceType)
        }
      },
      removeFile () {
        this.currentDefaultValue = null
        this.currentValue = null
        this.change()
      },
      change () {
        this.$emit('change', this.currentValue)
        this.$emit('input', this.currentValue)
      },
      onError (up, err, errTip) {
        this.$message.error('上传错误：err is [' + err + '] errTip is [' + errTip + ']')
        this.removeFile()
      },
      // 新上传文件被移除
      onFileRemove () {
        this.uploadFileList = []
        this.removeFile()
      },
      noFileInfo () {
        this.$message.warn('未上传文件不支持下载！')
      },
      downloadFile () {
        let downUrl = SERVICE_URLS.document.download.path.replace('{id}', this.currentDefaultValue.id) + '?attname=' + encodeURIComponent(this.currentDefaultValue.name)
        const xAuthToken = VueCookie.get(DEFAULT_X_AUTH_TOKEN)
        if (!xAuthToken) {
          this.$Message.error({
            closable: true,
            content: '下载凭证丢失，请重新登录！',
            duration: 3
          })
          return
        }
        downUrl += '&' + DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken
        /*eslint-disable-next-line*/
        downloadFile(downUrl)
      }
    },
    components: { LocalUpload }
  }
</script>