<template>
  <div style="width: 100%;height:100%;">
    <a-alert
      message="注意事项：1、明确使用场景；2、确定上传参数；3、回调方法处理。4、明确上传原理后使用。"
      type="warning"
    />
    <a-card style="margin-top: 10px;" title="多文件上传每个属性传一张：装备申请单中复杂使用场景。">
      <single-upload style="float: left" v-model="value1" title="采购项目计划申请表" @change="change1"></single-upload>
      <single-upload style="float: left" v-model="defaultValue.id" title="采购项目计划申请表1" @change="change2"
                     :defaultValue="defaultValue"></single-upload>
    </a-card>
    <a-card style="margin-top: 10px;" title="单文件上传">
      <single-upload style="float: left" v-model="value3" title="采购项目计划申请表2" @change="change3"></single-upload>
    </a-card>
    <a-card style="margin-top: 10px;" title="多文件上传：单据附件上传复杂使用场景">
      <multi-upload :filters="filters" v-model="defaultValue2"
                    :defaultValue="defaultListValue" @change="change4"
                    class="m-t-12"></multi-upload>
    </a-card>
    <a-card style="margin-top: 10px;" title="单文件上传">
      <single-simple-upload v-model="value5" :defaultValue="defaultValue" @change="change5"
                            class="m-t-12"></single-simple-upload>
    </a-card>
  </div>
</template>

<script>

  import SingleUpload from '../../../components/LocalUpload/SingleUpload'
  import SingleSimpleUpload from '../../../components/LocalUpload/SingleSimpleUpload'
  import MultiUpload from '../../../components/LocalUpload/MultiUpload'

  export default {
    name: 'uploadExample',
    data () {
      return {
        value1: null,
        value2: null,
        value3: null,
        value4: null,
        value5: null,
        filters: {
          mime_types: [ //只允许上传图片和zip文件
            { title: '支持文件拓展名:.doc .docx .pdf', extensions: 'doc,docx,pdf' }
          ],
        },
        defaultValue: {
          'dateCreatedStr': '刚刚',
          'downloadNo': 0,
          'uname': '系统管理员',
          'bucketUrl': 'http://localhost:9900/upload/',
          'h': 0,
          'parentId': 2,
          'uid': 1,
          'lastUpdated': '',
          'newName': '_d8db724151a743918b03d733da78c9ef.gz',
          'dateCreated': 1555577706411,
          'fileSize': 9403104.0,
          'w': 0,
          'name': 'GraphicsMagick-1.3.31.tar.gz',
          'creater': 'administrator',
          'fileUrl': '20190418/_d8db724151a743918b03d733da78c9ef.gz',
          'iconCode': '',
          'id': 132,
          'fileType': 'application/x-gzip'
        },
        defaultListValue: [
          {
            'dateCreatedStr': '刚刚',
            'downloadNo': 0,
            'uname': '系统管理员',
            'bucketUrl': 'http://localhost:9900/upload/',
            'h': 0,
            'parentId': 2,
            'uid': 1,
            'lastUpdated': '',
            'newName': '_40b0751c16c547dca83bd538e9210712.pdf',
            'dateCreated': 1555582118285,
            'fileSize': 7175427.0,
            'w': 0,
            'name': 'Arvix系统及盒子通用方案.pdf',
            'creater': 'administrator',
            'fileUrl': '20190418/_40b0751c16c547dca83bd538e9210712.pdf',
            'iconCode': '',
            'id': 145,
            'fileType': 'application/pdf'
          },
          {
            'dateCreatedStr': '刚刚',
            'downloadNo': 0,
            'uname': '系统管理员',
            'bucketUrl': 'http://localhost:9900/upload/',
            'h': 0,
            'parentId': 2,
            'uid': 1,
            'lastUpdated': '',
            'newName': '_27fa61855a234508b63fde8904b02fa8.pdf',
            'dateCreated': 1555582118055,
            'fileSize': 29182.0,
            'w': 0,
            'name': '74638852.pdf',
            'creater': 'administrator',
            'fileUrl': '20190418/_27fa61855a234508b63fde8904b02fa8.pdf',
            'iconCode': '',
            'id': 144,
            'fileType': 'application/pdf'
          },
          {
            'dateCreatedStr': '刚刚',
            'downloadNo': 0,
            'uname': '系统管理员',
            'bucketUrl': 'http://localhost:9900/upload/',
            'h': 0,
            'parentId': 2,
            'uid': 1,
            'lastUpdated': '',
            'newName': '_158a6739f5924b46a34652c6e996ed38.pdf',
            'dateCreated': 1555582117979,
            'fileSize': 29182.0,
            'w': 0,
            'name': '74638852-2.pdf',
            'creater': 'administrator',
            'fileUrl': '20190418/_158a6739f5924b46a34652c6e996ed38.pdf',
            'iconCode': '',
            'id': 143,
            'fileType': 'application/pdf'
          },
          {
            'dateCreatedStr': '刚刚',
            'downloadNo': 0,
            'uname': '系统管理员',
            'bucketUrl': 'http://localhost:9900/upload/',
            'h': 0,
            'parentId': 2,
            'uid': 1,
            'lastUpdated': '',
            'newName': '_c6161259b6b14e08b65517f7d1ae0b82.pdf',
            'dateCreated': 1555582117817,
            'fileSize': 247160.0,
            'w': 0,
            'name': '附件一：快乐咖APP需求分析书(1).pdf',
            'creater': 'administrator',
            'fileUrl': '20190418/_c6161259b6b14e08b65517f7d1ae0b82.pdf',
            'iconCode': '',
            'id': 142,
            'fileType': 'application/pdf'
          },
          {
            'dateCreatedStr': '刚刚',
            'downloadNo': 0,
            'uname': '系统管理员',
            'bucketUrl': 'http://localhost:9900/upload/',
            'h': 0,
            'parentId': 2,
            'uid': 1,
            'lastUpdated': '',
            'newName': '_fdbd5581398b4013a7d67b768d37615d.doc',
            'dateCreated': 1555582117709,
            'fileSize': 46080.0,
            'w': 0,
            'name': '郭奕agilemaster.com.cn域名注销.doc',
            'creater': 'administrator',
            'fileUrl': '20190418/_fdbd5581398b4013a7d67b768d37615d.doc',
            'iconCode': '',
            'id': 141,
            'fileType': 'application/msword'
          }],
        defaultValue2: []
      }
    },
    created () {
      const temp = []
      this.defaultListValue.forEach(x => {
        temp.push(x.id)
      })
      this.defaultValue2 = temp
    },
    methods: {
      change1 (val) {
        console.info(val)
      },
      change2 () {
        this.$nextTick(() => {
          console.info(this.value2)
        })
      },
      change3 () {
        this.$nextTick(() => {
          console.info(this.value3)
        })
      },
      change4 () {
        this.$nextTick(() => {
          console.info(this.defaultValue2)
        })
      },
      change5 () {
        this.$nextTick(() => {
          console.info(this.value5)
        })
      }
    },
    components: {
      SingleUpload, MultiUpload, SingleSimpleUpload
    },
  }
</script>